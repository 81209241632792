import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import RoomList from '../Room/RoomList';
import './HostelView.css';

const HostelView = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const [hostel, setHostel] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const token = localStorage.getItem('token');

    useEffect(() => {
        const fetchHostel = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`/api/hostels/${id}`, {
                    headers: {
                        'Accept': 'application/ld+json',
                        'Authorization': `Bearer ${token}`
                    },
                });
                setHostel(response.data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchHostel();
    }, [id, token]);

    if (loading) return <p>{t('loading')}</p>;
    if (error) return <p>{t('error_loading_hostel', { error })}</p>;

    return (
        <div>
            <h3>{hostel.name}</h3>
            <p>{hostel.address}</p>
            <RoomList />
        </div>
    );
};

export default HostelView;
