// src/components/HostelCreate.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './HostelCreate.css';

const HostelCreate = () => {
    const { t } = useTranslation();
    const [name, setName] = useState('');
    const [address, setAddress] = useState('');
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const { id } = useParams();
    const isEditing = !!id;

    const token = localStorage.getItem('token');

    useEffect(() => {
        if (isEditing) {
            const fetchHostel = async () => {
                try {
                    const response = await axios.get(`/api/hostels/${id}`, {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });
                    setName(response.data.name);
                    setAddress(response.data.address);
                } catch (err) {
                    setError(err.message);
                }
            };

            fetchHostel();
        }
    }, [id, isEditing, token]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            if (isEditing) {
                await axios.patch(`/api/hostels/${id}`, { name, address }, {
                    headers: {
                        'Content-Type': 'application/merge-patch+json',
                        'Authorization': `Bearer ${token}`
                    }
                });
            } else {
                await axios.post('/api/hostels', { name, address }, {
                    headers: {
                        'Content-Type': 'application/ld+json',
                        'Authorization': `Bearer ${token}`
                    }
                });
            }
            navigate('/hostels');
        } catch (err) {
            setError(err.message);
        }
    };

    return (
        <div className="form-container">
            <h2>{isEditing ? t('edit_hostel') : t('add_hostel')}</h2>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label>{t('name_label')}</label>
                    <input
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label>{t('address_label')}</label>
                    <input
                        type="text"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        required
                    />
                </div>
                <button type="submit">
                    {isEditing ? t('update_hostel') : t('create_hostel')}
                </button>
                {error && <p className="error">{t('error_message', { error })}</p>}
            </form>
        </div>
    );
};

export default HostelCreate;
