// src/AppContent.js
import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import Dashboard from './components/Dashboard';
import HostelList from './components/Hostel/HostelList';
import HostelCreate from './components/Hostel/HostelCreate';
import HostelView from './components/Hostel/HostelView';
import RoomCreate from './components/Room/RoomCreate';
import RoomView from './components/Room/RoomView';
import BedView from './components/Bed/BedView';
import ResidentList from './components/Resident/ResidentList';
import ResidentCreate from './components/Resident/ResidentCreate';
import ResidentMove from './components/Resident/ResidentMove';
import ResidentView from './components/Resident/ResidentView';
import RateCreate from './components/Rate/RateCreate';
import PaymentCreate from './components/Payment/PaymentCreate';
import Chessboard from './components/Chessboard/Chessboard';
import UserManagement from './components/UserManagement';
import Login from './components/Login';
import Register from './components/Register';
import './App.css';

const AppContent = () => {
    const location = useLocation();
    const noSidebarRoutes = ['/login', '/register'];

    return (
        <div className="container">
            {!noSidebarRoutes.includes(location.pathname) && <Sidebar />}
            <div className="content">
                <Routes>
                    <Route path="/" element={<Dashboard />} />
                    <Route path="/hostels" element={<HostelList />} />
                    <Route path="/hostels/create" element={<HostelCreate />} />
                    <Route path="/hostels/edit/:id" element={<HostelCreate />} />
                    <Route path="/hostels/:id" element={<HostelView />} />
                    <Route path="/hostels/:id/rooms/create" element={<RoomCreate />} />
                    <Route path="/rooms/:id" element={<RoomView />} />
                    <Route path="/rooms/:id/rates/create" element={<RateCreate />} />
                    <Route path="/beds/:id" element={<BedView />} />
                    <Route path="/residents" element={<ResidentList />} />
                    <Route path="/residents/create" element={<ResidentCreate />} />
                    <Route path="/residents/:id/move" element={<ResidentMove />} />
                    <Route path="/residents/edit/:id" element={<ResidentCreate />} />
                    <Route path="/residents/:id" element={<ResidentView />} />
                    <Route path="/residents/:id/payments/create" element={<PaymentCreate />} />
                    <Route path="/chessboard/:hostelId" element={<Chessboard />} />
                    <Route path="/users/manage" element={<UserManagement />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/register" element={<Register />} />
                </Routes>
            </div>
        </div>
    );
};

export default AppContent;
