// src/components/Resident/ResidentCreate.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './ResidentCreate.css';

const ResidentCreate = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const [name, setName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [availableBeds, setAvailableBeds] = useState([]);
    const [selectedHostel, setSelectedHostel] = useState('');
    const [selectedRoom, setSelectedRoom] = useState('');
    const [selectedBed, setSelectedBed] = useState('');
    const navigate = useNavigate();
    const token = localStorage.getItem('token');

    useEffect(() => {
        if (id) {
            axios.get(`/api/residents/${id}`, {
                headers: {
                    'Accept': 'application/ld+json',
                    'Authorization': `Bearer ${token}`
                },
            }).then(response => {
                const resident = response.data;
                setName(resident.name);
                setPhoneNumber(resident.phoneNumber);
                if (resident.bed) {
                    setSelectedHostel(resident.bed.room.hostel.id);
                    setSelectedRoom(resident.bed.room.id);
                    setSelectedBed(resident.bed.id);
                }
            });
        }

        axios.get('/api/available_beds', {
            headers: {
                'Accept': 'application/ld+json',
                'Authorization': `Bearer ${token}`
            },
        }).then(response => {
            setAvailableBeds(response.data.hostels);
        });
    }, [id, token]);

    const handleSubmit = async (event) => {
        event.preventDefault();

        const residentData = {
            name,
            phoneNumber,
            bed: selectedBed ? `/api/beds/${selectedBed}` : null
        };

        try {
            if (id) {
                await axios.put(`/api/residents/${id}`, residentData, {
                    headers: {
                        'Content-Type': 'application/ld+json',
                        'Authorization': `Bearer ${token}`
                    }
                });
                navigate(`/residents/${id}`);
            } else {
                const response = await axios.post('/api/residents', residentData, {
                    headers: {
                        'Content-Type': 'application/ld+json',
                        'Authorization': `Bearer ${token}`
                    }
                });
                navigate(`/residents/${response.data.id}`);
            }
        } catch (err) {
            console.error(err);
        }
    };

    const handleHostelChange = (e) => {
        setSelectedHostel(e.target.value);
        setSelectedRoom('');
        setSelectedBed('');
    };

    const handleRoomChange = (e) => {
        setSelectedRoom(e.target.value);
        setSelectedBed('');
    };

    return (
        <div className="resident-create">
            <h2>{id ? t('edit_resident') : t('add_resident')}</h2>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="name">{t('name')}</label>
                    <input
                        type="text"
                        id="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="phoneNumber">{t('phone_number')}</label>
                    <input
                        type="text"
                        id="phoneNumber"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="hostel">{t('hostel')}</label>
                    <select
                        id="hostel"
                        value={selectedHostel}
                        onChange={handleHostelChange}
                    >
                        <option value="">{t('select_hostel')}</option>
                        {Object.entries(availableBeds).map(([hostelId, hostel]) => (
                            <option key={hostelId} value={hostelId}>
                                {hostel.name}
                            </option>
                        ))}
                    </select>
                </div>
                {selectedHostel && availableBeds[selectedHostel]?.rooms && (
                    <div className="form-group">
                        <label htmlFor="room">{t('room')}</label>
                        <select
                            id="room"
                            value={selectedRoom}
                            onChange={handleRoomChange}
                        >
                            <option value="">{t('select_room')}</option>
                            {Object.entries(availableBeds[selectedHostel].rooms).map(([roomId, room]) => (
                                <option key={roomId} value={roomId}>
                                    {t('room')} {room.name}
                                </option>
                            ))}
                        </select>
                    </div>
                )}
                {selectedRoom && availableBeds[selectedHostel]?.rooms[selectedRoom]?.beds && (
                    <div className="form-group">
                        <label htmlFor="bed">{t('bed')}</label>
                        <select
                            id="bed"
                            value={selectedBed}
                            onChange={(e) => setSelectedBed(e.target.value)}
                        >
                            <option value="">{t('select_bed')}</option>
                            {Object.entries(availableBeds[selectedHostel].rooms[selectedRoom].beds)
                                .map(([bedId, bedName]) => (
                                    <option key={bedId} value={bedId}>
                                        {t('bed')} {bedName}
                                    </option>
                                ))}
                        </select>
                    </div>
                )}
                <button type="submit">{id ? t('update') : t('create')}</button>
            </form>
        </div>
    );
};

export default ResidentCreate;
