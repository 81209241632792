import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './ResidentMove.css';

const ResidentMove = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const [resident, setResident] = useState(null);
    const [availableBeds, setAvailableBeds] = useState([]);
    const [selectedHostel, setSelectedHostel] = useState('');
    const [selectedRoom, setSelectedRoom] = useState('');
    const [selectedBed, setSelectedBed] = useState('');
    const navigate = useNavigate();
    const token = localStorage.getItem('token');

    useEffect(() => {
        if (id) {
            axios.get(`/api/residents/${id}`, {
                headers: {
                    'Accept': 'application/ld+json',
                    'Authorization': `Bearer ${token}`
                },
            }).then(response => {
                const resident = response.data;
                setResident(resident);
                if (resident.bed) {
                    setSelectedHostel(resident.bed.room.hostel.id);
                    setSelectedRoom(resident.bed.room.roomNumber);
                    setSelectedBed(resident.bed.id);
                }
            });
        }

        axios.get('/api/available_beds', {
            headers: {
                'Accept': 'application/ld+json',
                'Authorization': `Bearer ${token}`
            },
        }).then(response => {
            setAvailableBeds(response.data.hostels);
        });
    }, [id, token]);

    const handleHostelChange = (e) => {
        setSelectedHostel(e.target.value);
        setSelectedRoom('');
        setSelectedBed('');
    };

    const handleRoomChange = (e) => {
        setSelectedRoom(e.target.value);
        setSelectedBed('');
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const residentData = {
            bed: selectedBed ? `/api/beds/${selectedBed}` : null
        };

        try {
            await axios.patch(`/api/residents/${id}/move`, residentData, {
                headers: {
                    'Content-Type': 'application/merge-patch+json',
                    'Authorization': `Bearer ${token}`
                }
            });
            navigate(`/residents/${id}`);
        } catch (err) {
            console.error(err);
        }
    };

    const getRoomsForSelectedHostel = () => {
        const hostel = availableBeds[selectedHostel];
        return hostel ? Object.entries(hostel.rooms) : [];
    };

    const getBedsForSelectedRoom = () => {
        const rooms = getRoomsForSelectedHostel();
        const room = rooms.find(([roomId, room]) => room.name == selectedRoom);
        return room ? Object.entries(room[1].beds) : [];
    };

    return (
        <div className="resident-move">
            <h2>{t('move_to_another_room')}</h2>
            {resident && (
                <div className="current-location">
                    <label>{t('current_location')}</label>
                    <input
                        type="text"
                        value={resident.bed ? `${t('room')} ${resident.bed.room.roomNumber} - ${t('bed')} ${resident.bed.name}` : t('not_assigned')}
                        readOnly
                    />
                </div>
            )}
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="hostel">{t('hostel')}</label>
                    <select
                        id="hostel"
                        value={selectedHostel}
                        onChange={handleHostelChange}
                    >
                        <option value="">{t('select_hostel')}</option>
                        {Object.entries(availableBeds).map(([hostelId, hostel]) => (
                            <option key={hostelId} value={hostelId}>
                                {hostel.name}
                            </option>
                        ))}
                    </select>
                </div>
                {selectedHostel && (
                    <div className="form-group">
                        <label htmlFor="room">{t('room')}</label>
                        <select
                            id="room"
                            value={selectedRoom}
                            onChange={handleRoomChange}
                        >
                            <option value="">{t('select_room')}</option>
                            {getRoomsForSelectedHostel().map(([roomId, room]) => (
                                <option key={roomId} value={room.name}>
                                    {t('room')} {room.name}
                                </option>
                            ))}
                        </select>
                    </div>
                )}
                {selectedRoom && (
                    <div className="form-group">
                        <label htmlFor="bed">{t('bed')}</label>
                        <select
                            id="bed"
                            value={selectedBed}
                            onChange={(e) => setSelectedBed(e.target.value)}
                        >
                            <option value="">{t('select_bed')}</option>
                            {getBedsForSelectedRoom().map(([bedId, bed]) => (
                                <option key={bedId} value={bedId}>
                                    {t('bed')} {bed}
                                </option>
                            ))}
                        </select>
                    </div>
                )}
                <button type="submit">{t('move')}</button>
            </form>
        </div>
    );
};

export default ResidentMove;
