// src/components/Room/RoomList.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './RoomList.css';

const RoomList = () => {
    const { t } = useTranslation();
    const { id } = useParams(); // Assuming id is the hostel id
    const [rooms, setRooms] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const token = localStorage.getItem('token');

    useEffect(() => {
        const fetchRooms = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`/api/rooms?hostel.id=${id}`, {
                    headers: {
                        'Accept': 'application/ld+json',
                        'Authorization': `Bearer ${token}`
                    }
                });
                setRooms(response.data['hydra:member']);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchRooms();
    }, [id, token]);

    const handleDelete = async (roomId) => {
        try {
            await axios.delete(`/api/rooms/${roomId}`, {
                headers: {
                    'Accept': 'application/ld+json',
                    'Authorization': `Bearer ${token}`
                }
            });
            setRooms(rooms.filter(room => room.id !== roomId));
        } catch (err) {
            setError(err.message);
        }
    };

    if (loading) return <p>{t('loading')}</p>;
    if (error) return <p>{t('error_loading_rooms', { error })}</p>;

    return (
        <div>
            <h3>{t('rooms')}</h3>
            <Link to={`/hostels/${id}/rooms/create`}>
                <button className="add-room-button">{t('add_room')}</button>
            </Link>
            {rooms.length > 0 ? (
                <ul className="room-list">
                    {rooms.map(room => (
                        <li key={room.id} className="room-list-item">
                            <div className="room-info">
                                <Link to={`/rooms/${room.id}`} className="room-link">
                                    <span className="room-number">{t('room')} {room.roomNumber}</span>
                                </Link>
                                <div className="bed-circles">
                                    {room.beds.map(bed => (
                                        <Link to={`/beds/${bed.id}`} key={bed.id} className="bed-link">
                                            <div className={`bed-circle ${bed.resident ? 'occupied' : 'vacant'}`}></div>
                                        </Link>
                                    ))}
                                </div>
                                <button className="delete-button" onClick={() => handleDelete(room.id)}>{t('delete')}</button>
                            </div>
                        </li>
                    ))}
                </ul>
            ) : (
                <p>{t('no_rooms_available')}</p>
            )}
        </div>
    );
};

export default RoomList;
