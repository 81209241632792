// src/components/Resident/ResidentView.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './ResidentView.css';

const ResidentView = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const navigate = useNavigate();
    const [resident, setResident] = useState(null);
    const [payments, setPayments] = useState([]);
    const [debt, setDebt] = useState(null);
    const [debtAmount, setDebtAmount] = useState('');
    const [debtDescription, setDebtDescription] = useState('');
    const [isEditingDebt, setIsEditingDebt] = useState(false);
    const [showDebtForm, setShowDebtForm] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [editingPayment, setEditingPayment] = useState(null);
    const [editedPaymentData, setEditedPaymentData] = useState({});
    const token = localStorage.getItem('token');

    useEffect(() => {
        const fetchResident = async () => {
            try {
                const residentResponse = await axios.get(`/api/residents/${id}`, {
                    headers: {
                        'Accept': 'application/ld+json',
                        'Authorization': `Bearer ${token}`
                    }
                });
                setResident(residentResponse.data);

                const paymentsResponse = await axios.get(`/api/payments?resident.id=${id}&order[endDate]=desc`, {
                    headers: {
                        'Accept': 'application/ld+json',
                        'Authorization': `Bearer ${token}`
                    }
                });
                setPayments(paymentsResponse.data['hydra:member']);

                const debtResponse = await axios.get(`/api/debts?resident.id=${id}`, {
                    headers: {
                        'Accept': 'application/ld+json',
                        'Authorization': `Bearer ${token}`
                    }
                });
                if (debtResponse.data['hydra:member'].length > 0) {
                    const residentDebt = debtResponse.data['hydra:member'][0];
                    setDebt(residentDebt);
                    setDebtAmount(residentDebt.amount);
                    setDebtDescription(residentDebt.description);
                }
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchResident();
    }, [id, token]);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}.${month}.${year}`;
    };

    const extractDate = (dateString) => {
        return dateString ? dateString.split('T')[0] : '';
    };

    const formatDateForAPI = (dateString) => {
        return new Date(dateString).toISOString();
    };

    const handleAddDebt = async () => {
        const debtData = {
            amount: parseFloat(debtAmount),
            description: debtDescription,
            resident: `/api/residents/${id}`
        };

        try {
            const response = await axios.post('/api/debts', debtData, {
                headers: {
                    'Content-Type': 'application/ld+json',
                    'Authorization': `Bearer ${token}`
                }
            });
            setDebt(response.data);
            setShowDebtForm(false);
        } catch (err) {
            console.error(err);
        }
    };

    const handleUpdateDebt = async () => {
        const debtData = {
            amount: parseFloat(debtAmount),
            description: debtDescription,
            resident: `/api/residents/${id}`
        };

        try {
            await axios.put(`/api/debts/${debt.id}`, debtData, {
                headers: {
                    'Content-Type': 'application/ld+json',
                    'Authorization': `Bearer ${token}`
                }
            });
            setDebtAmount(debtData.amount);
            setDebtDescription(debtData.description);
            setIsEditingDebt(false);
        } catch (err) {
            console.error(err);
        }
    };

    const handleDeleteDebt = async () => {
        try {
            await axios.delete(`/api/debts/${debt.id}`, {
                headers: {
                    'Accept': 'application/ld+json',
                    'Authorization': `Bearer ${token}`
                }
            });
            setDebt(null);
            setDebtAmount('');
            setDebtDescription('');
        } catch (err) {
            console.error(err);
        }
    };

    const handleMove = () => {
        if (window.confirm(t('move_to_another_room'))) {
            navigate(`/residents/${id}/move`);
        }
    };

    const handleCheckOut = async () => {
        if (window.confirm(t('check_out'))) {
            try {
                await axios.patch(`/api/residents/${id}/checkout`, {}, {
                    headers: {
                        'Content-Type': 'application/merge-patch+json',
                        'Authorization': `Bearer ${token}`
                    }
                });
                navigate('/residents');
            } catch (err) {
                console.error(err);
            }
        }
    };

    const handleAssign = () => {
        navigate(`/residents/edit/${id}`);
    };

    const handleEditPayment = (payment) => {
        setEditingPayment(payment);
        setEditedPaymentData({
            amount: payment.amount,
            period: payment.period,
            paymentDate: extractDate(payment.paymentDate),
            startDate: extractDate(payment.startDate),
            endDate: extractDate(payment.endDate),
        });
    };

    const handleUpdatePayment = async () => {
        const paymentData = {
            amount: parseFloat(editedPaymentData.amount),
            period: editedPaymentData.period,
            paymentDate: editedPaymentData.paymentDate ? formatDateForAPI(editedPaymentData.paymentDate) : null,
            startDate: formatDateForAPI(editedPaymentData.startDate),
            endDate: formatDateForAPI(editedPaymentData.endDate),
            resident: `/api/residents/${id}`,
            bed: `/api/beds/${resident.bed.id}`
        };

        try {
            await axios.put(`/api/payments/${editingPayment.id}`, paymentData, {
                headers: {
                    'Content-Type': 'application/ld+json',
                    'Authorization': `Bearer ${token}`
                }
            });
            // Update payments in state
            const updatedPayments = payments.map(payment =>
                payment.id === editingPayment.id ? { ...payment, ...paymentData } : payment
            );
            setPayments(updatedPayments);
            setEditingPayment(null);
        } catch (err) {
            console.error(err);
        }
    };

    const handleDeletePayment = async (paymentId) => {
        if (window.confirm(t('delete'))) {
            try {
                await axios.delete(`/api/payments/${paymentId}`, {
                    headers: {
                        'Accept': 'application/ld+json',
                        'Authorization': `Bearer ${token}`
                    }
                });
                setPayments(payments.filter(payment => payment.id !== paymentId));
            } catch (err) {
                console.error(err);
            }
        }
    };

    if (loading) return <p>{t('loading')}</p>;
    if (error) return <p>{t('error_loading_resident', { error })}</p>;

    return (
        <div>
            <h3>{t('resident_details')}</h3>
            {resident && (
                <div className="resident-details">
                    <p><strong>{t('name')}:</strong> {resident.name}</p>
                    <p><strong>{t('phone_number')}:</strong> {resident.phoneNumber}</p>
                    {resident.bed && (
                        <>
                            <p><strong>{t('room')}:</strong> <Link to={`/rooms/${resident.bed.room.id}`}>{t('room')} {resident.bed.room.roomNumber}</Link></p>
                            <p><strong>{t('bed')}:</strong> <Link to={`/beds/${resident.bed.id}`}>{t('bed')} {resident.bed.name}</Link></p>
                        </>
                    )}
                </div>
            )}
            <h3>{t('payments')}</h3>
            {resident && resident.bed && (
                <Link to={`/residents/${id}/payments/create`}>
                    <button className="add-payment-button">{t('add_payment')}</button>
                </Link>
            )}
            {payments.length > 0 ? (
                <table className="payments-table">
                    <thead>
                    <tr>
                        <th>{t('amount')}</th>
                        <th>{t('period')}</th>
                        <th>{t('payment_date')}</th>
                        <th>{t('start_date')}</th>
                        <th>{t('end_date')}</th>
                        <th>{t('actions')}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {payments.map(payment => (
                        <tr key={payment.id} style={{ backgroundColor: payment.paymentDate ? '' : 'lightyellow' }}>
                            <td>{payment.amount}</td>
                            <td>{payment.period}</td>
                            <td>{payment.paymentDate ? formatDate(payment.paymentDate) : t('awaiting_payment')}</td>
                            <td>{formatDate(payment.startDate)}</td>
                            <td>{formatDate(payment.endDate)}</td>
                            <td>
                                <button onClick={() => handleEditPayment(payment)}>{t('edit')}</button>
                                <button onClick={() => handleDeletePayment(payment.id)}>{t('delete')}</button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            ) : (
                <p>{t('no_payments_available')}</p>
            )}
            {editingPayment && (
                <div className="edit-payment-form">
                    <h4>{t('edit_payment')}</h4>
                    <div className="form-group">
                        <label>{t('amount')}:</label>
                        <input
                            type="number"
                            value={editedPaymentData.amount}
                            onChange={(e) => setEditedPaymentData({ ...editedPaymentData, amount: e.target.value })}
                        />
                    </div>
                    <div className="form-group">
                        <label>{t('period')}:</label>
                        <select
                            value={editedPaymentData.period}
                            onChange={(e) => setEditedPaymentData({ ...editedPaymentData, period: e.target.value })}
                        >
                            <option value="day">{t('day')}</option>
                            <option value="week">{t('week')}</option>
                            <option value="month">{t('month')}</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <label>{t('payment_date')}:</label>
                        <input
                            type="date"
                            value={editedPaymentData.paymentDate}
                            onChange={(e) => setEditedPaymentData({ ...editedPaymentData, paymentDate: e.target.value })}
                        />
                    </div>
                    <div className="form-group">
                        <label>{t('start_date')}:</label>
                        <input
                            type="date"
                            value={editedPaymentData.startDate}
                            onChange={(e) => setEditedPaymentData({ ...editedPaymentData, startDate: e.target.value })}
                        />
                    </div>
                    <div className="form-group">
                        <label>{t('end_date')}:</label>
                        <input
                            type="date"
                            value={editedPaymentData.endDate}
                            onChange={(e) => setEditedPaymentData({ ...editedPaymentData, endDate: e.target.value })}
                        />
                    </div>
                    <button onClick={handleUpdatePayment}>{t('save')}</button>
                    <button onClick={() => setEditingPayment(null)}>{t('cancel')}</button>
                </div>
            )}
            <h3>{t('debt')}</h3>
            {debt ? (
                <div className="debt-section">
                    {isEditingDebt ? (
                        <div className="debt-form">
                            <div className="form-group">
                                <label>{t('amount')}:</label>
                                <input
                                    type="number"
                                    value={debtAmount}
                                    onChange={(e) => setDebtAmount(e.target.value)}
                                />
                            </div>
                            <div className="form-group">
                                <label>{t('description')}:</label>
                                <input
                                    type="text"
                                    value={debtDescription}
                                    onChange={(e) => setDebtDescription(e.target.value)}
                                />
                            </div>
                            <button onClick={handleUpdateDebt}>{t('save')}</button>
                            <button onClick={() => setIsEditingDebt(false)}>{t('cancel')}</button>
                        </div>
                    ) : (
                        <div className="debt-details">
                            <p><strong>{t('amount')}:</strong> {debt.amount}</p>
                            <p><strong>{t('description')}:</strong> {debt.description}</p>
                            <button onClick={() => setIsEditingDebt(true)}>{t('edit')}</button>
                            <button onClick={handleDeleteDebt}>{t('delete')}</button>
                        </div>
                    )}
                </div>
            ) : (
                !showDebtForm && (
                    <button onClick={() => setShowDebtForm(true)}>{t('add_debt')}</button>
                )
            )}
            {showDebtForm && (
                <div className="debt-form">
                    <div className="form-group">
                        <label>{t('amount')}:</label>
                        <input
                            type="number"
                            value={debtAmount}
                            onChange={(e) => setDebtAmount(e.target.value)}
                        />
                    </div>
                    <div className="form-group">
                        <label>{t('description')}:</label>
                        <input
                            type="text"
                            value={debtDescription}
                            onChange={(e) => setDebtDescription(e.target.value)}
                        />
                    </div>
                    <button onClick={handleAddDebt}>{t('add_debt')}</button>
                </div>
            )}
            <div className="resident-actions">
                {resident && resident.bed ? (
                    <>
                        <button onClick={handleMove}>{t('move_to_another_room')}</button>
                        <button onClick={handleCheckOut}>{t('check_out')}</button>
                    </>
                ) : (
                    <button onClick={handleAssign}>{t('assign')}</button>
                )}
            </div>
        </div>
    );
};

export default ResidentView;
