// src/components/HostelList.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './HostelList.css';

const HostelList = () => {
    const { t } = useTranslation();
    const [hostels, setHostels] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const token = localStorage.getItem('token');

    useEffect(() => {
        const fetchHostels = async () => {
            try {
                const response = await axios.get('/api/hostels', {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                setHostels(response.data['hydra:member']);
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        fetchHostels();
    }, [token]);

    const handleDelete = async (id) => {
        try {
            await axios.delete(`/api/hostels/${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            setHostels(hostels.filter(hostel => hostel.id !== id));
        } catch (err) {
            setError(err);
        }
    };

    if (loading) return <p>{t('loading')}</p>;
    if (error) return <p>{t('error_loading_hostels', { error: error.message })}</p>;

    return (
        <div>
            <h2>{t('hostel_listing')}</h2>
            <Link to="/hostels/create">
                <button>{t('add_hostel')}</button>
            </Link>
            <table>
                <thead>
                <tr>
                    <th>{t('id')}</th>
                    <th>{t('name')}</th>
                    <th>{t('address')}</th>
                    <th>{t('actions')}</th>
                </tr>
                </thead>
                <tbody>
                {hostels.map(hostel => (
                    <tr key={hostel.id}>
                        <td>{hostel.id}</td>
                        <td>{hostel.name}</td>
                        <td>{hostel.address}</td>
                        <td>
                            <button style={{ backgroundColor: 'green', color: 'white' }} onClick={() => navigate(`/hostels/${hostel.id}`)}>
                                {t('view')}
                            </button>
                            <button onClick={() => navigate(`/hostels/edit/${hostel.id}`)}>{t('edit')}</button>
                            <button onClick={() => handleDelete(hostel.id)}>{t('delete')}</button>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default HostelList;
