// src/components/Dashboard.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import './Dashboard.css';

const Dashboard = () => {
    const { t } = useTranslation();

    const [debts, setDebts] = useState([]);
    const [monthlyIncome, setMonthlyIncome] = useState(0);
    const [todayPayments, setTodayPayments] = useState([]);
    const [tomorrowPayments, setTomorrowPayments] = useState([]);
    const [dayAfterTomorrowPayments, setDayAfterTomorrowPayments] = useState([]);

    const token = localStorage.getItem('token');

    useEffect(() => {
        // Fetching debtors
        const fetchDebtors = async () => {
            try {
                const response = await axios.get('/api/debts', {
                    headers: {
                        'Accept': 'application/ld+json',
                        'Authorization': `Bearer ${token}`,
                    },
                    params: {
                        amount: 'gt=0',
                    },
                });
                setDebts(response.data['hydra:member']);
            } catch (error) {
                console.error('Error fetching debtors:', error);
            }
        };

        // Fetching payments for the current month
        const fetchPayments = async () => {
            try {
                const response = await axios.get('/api/payments_daily', {
                    headers: {
                        'Accept': 'application/ld+json',
                        'Authorization': `Bearer ${token}`,
                    },
                });

                // Summing up all daily payments
                const totalIncome = response.data.reduce((total, payment) => {
                    return total + parseFloat(payment.daily_payment);
                }, 0);

                setMonthlyIncome(totalIncome);
            } catch (error) {
                console.error('Error fetching payments:', error);
            }
        };

        // Fetching upcoming payments (last payments)
        const fetchUpcomingPayments = async () => {
            try {
                const response = await axios.get('/api/last_payments', {
                    headers: {
                        'Accept': 'application/ld+json',
                        'Authorization': `Bearer ${token}`,
                    },
                });

                // Current date and next two days
                const today = new Date();
                today.setHours(0, 0, 0, 0);
                const tomorrow = new Date(today);
                tomorrow.setDate(today.getDate() + 1);
                const dayAfterTomorrow = new Date(today);
                dayAfterTomorrow.setDate(today.getDate() + 2);

                // Categorizing payments
                const todayList = [];
                const tomorrowList = [];
                const dayAfterTomorrowList = [];

                response.data['hydra:member'].forEach((payment) => {
                    const lastPaymentDate = new Date(payment.lastPayment);
                    lastPaymentDate.setHours(0, 0, 0, 0);

                    if (lastPaymentDate <= today) {
                        todayList.push(payment);
                    } else if (lastPaymentDate.toDateString() === tomorrow.toDateString()) {
                        tomorrowList.push(payment);
                    } else if (lastPaymentDate.toDateString() === dayAfterTomorrow.toDateString()) {
                        dayAfterTomorrowList.push(payment);
                    }
                });

                setTodayPayments(todayList);
                setTomorrowPayments(tomorrowList);
                setDayAfterTomorrowPayments(dayAfterTomorrowList);
            } catch (error) {
                console.error('Error fetching upcoming payments:', error);
            }
        };

        fetchDebtors();
        fetchPayments();
        fetchUpcomingPayments();
    }, [token]);

    return (
        <div className="dashboard">
            {/* Block 1: Debtors */}
            <div className="dashboard-block">
                <h2>{t('debtors')}</h2>
                {debts.length > 0 ? (
                    <ul>
                        {debts.map((debt) => (
                            <li key={debt.id}>
                                {t('resident')}: {debt.resident?.name || 'Unknown'}, {t('amount')}: {debt.amount}
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p>{t('no_debtors')}</p>
                )}
            </div>

            {/* Block 2: Monthly Income */}
            <div className="dashboard-block">
                <h2>{t('monthly_income')}</h2>
                <p>{t('total_income')}: {monthlyIncome.toFixed(2)}</p>
            </div>

            {/* Block 3: Upcoming Payments */}
            <div className="dashboard-block">
                <h2>{t('upcoming_payments')}</h2>

                {/* Payments for Today */}
                <h3>{t('today_and_past_due')}</h3>
                {todayPayments.length > 0 ? (
                    <ul>
                        {todayPayments.map((payment) => (
                            <li key={payment.id}>
                                {payment.name} ({t('bed_id')}: {payment.bedId}) - {t('last_payment')}: {new Date(payment.lastPayment).toLocaleDateString()}
                                <br />
                                <a href={`tel:${payment.phoneNumber}`}>{t('call')}: {payment.phoneNumber}</a>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p>{t('no_payments_today')}</p>
                )}

                {/* Payments for Tomorrow */}
                <h3>{t('tomorrow')}</h3>
                {tomorrowPayments.length > 0 ? (
                    <ul>
                        {tomorrowPayments.map((payment) => (
                            <li key={payment.id}>
                                {payment.name} ({t('bed_id')}: {payment.bedId}) - {t('last_payment')}: {new Date(payment.lastPayment).toLocaleDateString()}
                                <br />
                                <a href={`tel:${payment.phoneNumber}`}>{t('call')}: {payment.phoneNumber}</a>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p>{t('no_payments_tomorrow')}</p>
                )}

                {/* Payments for Day After Tomorrow */}
                <h3>{t('day_after_tomorrow')}</h3>
                {dayAfterTomorrowPayments.length > 0 ? (
                    <ul>
                        {dayAfterTomorrowPayments.map((payment) => (
                            <li key={payment.id}>
                                {payment.name} ({t('bed_id')}: {payment.bedId}) - {t('last_payment')}: {new Date(payment.lastPayment).toLocaleDateString()}
                                <br />
                                <a href={`tel:${payment.phoneNumber}`}>{t('call')}: {payment.phoneNumber}</a>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p>{t('no_payments_day_after_tomorrow')}</p>
                )}
            </div>
        </div>
    );
};

export default Dashboard;
