import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import 'react-datepicker/dist/react-datepicker.css';
import './PaymentCreate.css';

const PaymentCreate = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const navigate = useNavigate();
    const [amount, setAmount] = useState(0);
    const [period, setPeriod] = useState('day');
    const [duration, setDuration] = useState(1);
    const [paymentDate, setPaymentDate] = useState(new Date());
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [isAmountEditable, setIsAmountEditable] = useState(false);
    const [rate, setRate] = useState(null);
    const token = localStorage.getItem('token');

    useEffect(() => {
        const fetchLastPaymentDate = async () => {
            try {
                const response = await axios.get(`/api/payments?resident.id=${id}&order[endDate]=desc`, {
                    headers: {
                        'Accept': 'application/ld+json',
                        'Authorization': `Bearer ${token}`
                    }
                });
                if (response.data['hydra:member'].length > 0) {
                    const lastEndDate = new Date(response.data['hydra:member'][0].endDate);
                    setStartDate(new Date(lastEndDate.getTime())); // +1 day
                } else {
                    setStartDate(new Date());
                }
                setEndDate(calculateEndDate(new Date(startDate), period, duration));
            } catch (err) {
                console.error(err);
            }
        };

        const fetchResidentRate = async () => {
            try {
                const response = await axios.get(`/api/residents/${id}/rates`, {
                    headers: {
                        'Accept': 'application/ld+json',
                        'Authorization': `Bearer ${token}`
                    }
                });
                if (response.data.length > 0) {
                    setRate(response.data[0]);
                }
            } catch (err) {
                console.error(err);
            }
        };

        fetchLastPaymentDate();
        fetchResidentRate();
    }, [id]);

    useEffect(() => {
        setEndDate(calculateEndDate(startDate, period, duration));
        if (rate) {
            calculateAmount();
        }
    }, [startDate, period, duration, rate]);

    const calculateEndDate = (startDate, period, duration) => {
        const endDate = new Date(startDate);
        if (period === 'day') {
            endDate.setDate(endDate.getDate() + duration);
        } else if (period === 'week') {
            endDate.setDate(endDate.getDate() + duration * 7);
        } else if (period === 'month') {
            endDate.setMonth(endDate.getMonth() + duration);
            endDate.setDate(endDate.getDate()); // +1 day
            // Add days to make it 30 if less
            if ((endDate - startDate) / (1000 * 60 * 60 * 24) < 30) {
                endDate.setDate(endDate.getDate() + 30 - (endDate - startDate) / (1000 * 60 * 60 * 24));
            }
        }
        return endDate;
    };

    const calculateAmount = () => {
        let calculatedAmount = 0;
        if (period === 'day') {
            calculatedAmount = duration * rate.dailyRate;
        } else if (period === 'week') {
            calculatedAmount = duration * rate.weeklyRate;
        } else if (period === 'month') {
            calculatedAmount = duration * rate.monthlyRate;
        }
        setAmount(calculatedAmount);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.post('/api/payments', {
                resident: `/api/residents/${id}`,
                amount: parseFloat(amount),
                period,
                paymentDate: paymentDate ? paymentDate.toISOString().split('T')[0] : null, // Allow null
                startDate: startDate.toISOString().split('T')[0],
                endDate: endDate.toISOString().split('T')[0]
            }, {
                headers: {
                    'Content-Type': 'application/ld+json',
                    'Authorization': `Bearer ${token}`
                }
            });
            navigate(`/residents/${id}`);
        } catch (err) {
            console.error(err);
        }
    };

    const handlePeriodValueChange = (delta) => {
        setDuration((prev) => Math.max(1, prev + delta));
    };

    return (
        <div>
            <h3>{t('create_payment')}</h3>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label>{t('period')}</label>
                    <select value={period} onChange={e => setPeriod(e.target.value)}>
                        <option value="day">{t('day')}</option>
                        <option value="week">{t('week')}</option>
                        <option value="month">{t('month')}</option>
                    </select>
                </div>
                <div className="form-group">
                    <label>{t('duration')}</label>
                    <input
                        type="number"
                        value={duration}
                        onChange={e => setDuration(parseInt(e.target.value))}
                        min="1"
                    />
                </div>
                <div className="form-group">
                    <label>{t('amount')}</label>
                    <input
                        type="number"
                        value={amount}
                        onChange={e => setAmount(e.target.value)}
                        readOnly={!isAmountEditable}
                    />
                    <input
                        type="checkbox"
                        checked={isAmountEditable}
                        onChange={e => setIsAmountEditable(e.target.checked)}
                    />
                    {t('enable_editing')}
                </div>
                <div className="form-group">
                    <label>{t('payment_date')}</label>
                    <DatePicker
                        selected={paymentDate}
                        onChange={date => setPaymentDate(date)}
                        dateFormat="dd.MM.yyyy"
                        isClearable
                    />
                </div>
                <div className="form-group">
                    <label>{t('start_date')}</label>
                    <DatePicker
                        selected={startDate}
                        onChange={date => setStartDate(date)}
                        dateFormat="dd.MM.yyyy"
                    />
                </div>
                <div className="form-group">
                    <label>{t('end_date')}</label>
                    <DatePicker
                        selected={endDate}
                        onChange={date => setEndDate(date)}
                        dateFormat="dd.MM.yyyy"
                        readOnly
                    />
                </div>
                <button type="submit">{t('submit_payment')}</button>
            </form>
        </div>
    );
};

export default PaymentCreate;
