// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/Register.css */
.register-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.register-form {
    display: flex;
    flex-direction: column;
    width: 300px;
}

.register-form input {
    margin-bottom: 10px;
    padding: 10px;
    font-size: 16px;
}

.register-form button {
    padding: 10px;
    font-size: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Register.css"],"names":[],"mappings":"AAAA,gCAAgC;AAChC;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,YAAY;AAChB;;AAEA;IACI,mBAAmB;IACnB,aAAa;IACb,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,eAAe;AACnB","sourcesContent":["/* src/components/Register.css */\n.register-container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    height: 100vh;\n}\n\n.register-form {\n    display: flex;\n    flex-direction: column;\n    width: 300px;\n}\n\n.register-form input {\n    margin-bottom: 10px;\n    padding: 10px;\n    font-size: 16px;\n}\n\n.register-form button {\n    padding: 10px;\n    font-size: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
