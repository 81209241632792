// src/components/Room/RoomCreate.js
import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './RoomCreate.css';

const RoomCreate = () => {
    const { t } = useTranslation();
    const { id: hostelId } = useParams();
    const [roomNumber, setRoomNumber] = useState('');
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const token = localStorage.getItem('token');

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            await axios.post('/api/rooms', {
                roomNumber: parseInt(roomNumber, 10),
                hostel: `/api/hostels/${hostelId}`
            }, {
                headers: {
                    'Content-Type': 'application/ld+json',
                    'Authorization': `Bearer ${token}`
                }
            });
            navigate(`/hostels/${hostelId}`);
        } catch (err) {
            setError(err.message);
        }
    };

    return (
        <div className="form-container">
            <h2>{t('add_room')}</h2>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label>{t('room_number')}:</label>
                    <input
                        type="text"
                        value={roomNumber}
                        onChange={(e) => setRoomNumber(e.target.value)}
                        required
                    />
                </div>
                <button type="submit">{t('add')}</button>
                {error && <p className="error">{t('error', { error })}</p>}
            </form>
        </div>
    );
};

export default RoomCreate;
