import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import 'react-datepicker/dist/react-datepicker.css';
import './Chessboard.css';

const Chessboard = () => {
    const { t } = useTranslation();
    const { hostelId } = useParams();
    const [rooms, setRooms] = useState([]);
    const [payments, setPayments] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [currentMonth, setCurrentMonth] = useState(new Date());
    const [currentWeek, setCurrentWeek] = useState(new Date());
    const token = localStorage.getItem('token');

    const isMobile = window.innerWidth <= 768;

    useEffect(() => {
        const now = new Date();

        if (!startDate && !endDate) {
            if (isMobile) {
                const yesterday = new Date(now);
                yesterday.setDate(now.getDate() - 1);
                const fiveDaysLater = new Date(yesterday);
                fiveDaysLater.setDate(yesterday.getDate() + 4);
                setStartDate(yesterday);
                setEndDate(fiveDaysLater);
            } else {
                setStartDate(new Date(now.getFullYear(), now.getMonth(), 1));
                setEndDate(new Date(now.getFullYear(), now.getMonth() + 1, 0));
            }
        }
    }, [isMobile, startDate, endDate]);

    useEffect(() => {
        const fetchRooms = async () => {
            const response = await axios.get('/api/rooms', {
                params: {
                    'hostel.id': hostelId
                },
                headers: {
                    'Accept': 'application/ld+json',
                    'Authorization': `Bearer ${token}`
                }
            });
            setRooms(response.data['hydra:member']);
        };

        const fetchPayments = async () => {
            const adjustedEndDate = new Date(endDate);
            adjustedEndDate.setDate(adjustedEndDate.getDate() + 1);

            const response = await axios.get('/api/payments_daily', {
                params: {
                    start_date: startDate.toISOString().split('T')[0],
                    end_date: adjustedEndDate.toISOString().split('T')[0],
                },
                headers: {
                    'Accept': 'application/ld+json',
                    'Authorization': `Bearer ${token}`
                }
            });
            setPayments(response.data);
        };

        if (startDate && endDate) {
            fetchRooms();
            fetchPayments();
        }
    }, [startDate, endDate, hostelId]);

    const handleMonthChange = (direction) => {
        const newMonth = new Date(currentMonth.setMonth(currentMonth.getMonth() + direction));
        setCurrentMonth(newMonth);
        setStartDate(new Date(newMonth.getFullYear(), newMonth.getMonth(), 1));
        setEndDate(new Date(newMonth.getFullYear(), newMonth.getMonth() + 1, 0));
    };

    const handleWeekChange = (direction) => {
        const newWeek = new Date(currentWeek.setDate(currentWeek.getDate() + direction * 7));
        setCurrentWeek(newWeek);

        const startOfWeek = new Date(newWeek.setDate(newWeek.getDate() - newWeek.getDay() + 1));
        startOfWeek.setHours(0, 0, 0, 0);

        const endOfWeek = new Date(startOfWeek.getFullYear(), startOfWeek.getMonth(), startOfWeek.getDate() + 6);
        endOfWeek.setHours(23, 59, 59, 999);

        setStartDate(startOfWeek);
        setEndDate(endOfWeek);
    };

    const renderDates = () => {
        const dates = [];
        const currentDate = new Date(startDate);
        while (currentDate <= endDate) {
            dates.push(new Date(currentDate));
            currentDate.setDate(currentDate.getDate() + 1);
        }
        return dates;
    };

    const formatDate = (date) => date.toLocaleDateString('pl-PL', { day: '2-digit', month: '2-digit' });

    const formatDateZend = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const dates = renderDates();

    const getPaymentForDate = (bedId, date) => {
        const dateStr = formatDateZend(date);
        return payments.find(payment => payment.date_of_month === dateStr && payment.bed_id === bedId);
    };

    const isDayOfPayment = (bedId, date) => {
        const dateStr = formatDateZend(date);
        return payments.find(payment => payment.payment_date === dateStr && payment.bed_id === bedId);
    };

    return (
        <div className="chessboard-container">
            <div className="filter-container">
                <button onClick={() => handleMonthChange(-1)}>{t('previous_month')}</button>
                <span>{currentMonth.toLocaleDateString('ru-RU', { month: 'long', year: 'numeric' })}</span>
                <button onClick={() => handleMonthChange(1)}>{t('next_month')}</button>
            </div>
            <div className="filter-container">
                <button onClick={() => handleWeekChange(-1)}>{t('previous_week')}</button>
                <span>{`${t('week_of')} ${currentWeek.toLocaleDateString('ru-RU', { day: '2-digit', month: '2-digit', year: 'numeric' })}`}</span>
                <button onClick={() => handleWeekChange(1)}>{t('next_week')}</button>
            </div>
            <div className="filter-container">
                <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} dateFormat="dd.MM.yyyy" />
                <span>-</span>
                <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} dateFormat="dd.MM.yyyy" />
            </div>
            <table className="chessboard">
                <thead>
                <tr>
                    <th>{t('bed_date')}</th>
                    {dates.map(date => (
                        <th key={date} className={date.getDay() === 0 || date.getDay() === 6 ? 'weekend' : ''}>
                            {formatDate(date)}
                        </th>
                    ))}
                </tr>
                </thead>
                <tbody>
                {rooms.map(room => (
                    room.beds.map(bed => (
                        <tr key={bed.id}>
                            <td>{`${room.roomNumber}-${bed.name}`}</td>
                            {dates.map(date => {
                                const paymentForDate = getPaymentForDate(bed.id, date);
                                return (
                                    <td key={`${bed.id}-${date}`}>
                                        {paymentForDate ? (
                                            <div className={`${
                                                paymentForDate.faktura ? 'faktura' : 'paid'
                                            } ${
                                                isDayOfPayment(bed.id, date) ? 'payment-date-class' : ''
                                            }`}>
                                                {typeof paymentForDate.daily_payment === 'number' ? paymentForDate.daily_payment.toFixed(2) : paymentForDate.daily_payment}<br/>
                                                <a href={`/residents/${paymentForDate.resident_id}`}>{paymentForDate.resident_id}</a>
                                            </div>
                                        ) : (
                                            <div className="unpaid"></div>
                                        )}
                                    </td>
                                );
                            })}
                        </tr>
                    ))
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default Chessboard;
