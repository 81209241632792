// src/components/Sidebar.js
import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from './LanguageSwitcher';
import './Sidebar.css';

const Sidebar = () => {
    const [hostels, setHostels] = useState([]);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const location = useLocation();
    const { t, ready } = useTranslation();

    const token = localStorage.getItem('token');

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    useEffect(() => {
        const fetchHostels = async () => {
            try {
                const response = await axios.get('/api/hostels', {
                    headers: {
                        'Accept': 'application/ld+json',
                        'Authorization': `Bearer ${token}`
                    }
                });
                setHostels(response.data['hydra:member']);
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    window.location.href = '/login';
                }
            }
        };

        fetchHostels();
    }, [token]);

    const noSidebarRoutes = ['/login', '/register'];

    if (!ready) {
        return <div>Loading translations...</div>;
    }

    if (noSidebarRoutes.includes(location.pathname)) {
        return null;
    }

    const handleLinkClick = () => {
        if (window.innerWidth <= 768) {
            setIsSidebarOpen(false);
        }
    };

    return (
        <>
            <button className="hamburger" onClick={toggleSidebar}>
                &#9776;
            </button>
            <div className={`sidebar ${isSidebarOpen ? 'open' : ''}`}>
                <h2><Link to="/" onClick={handleLinkClick}>{t('home')}</Link></h2>
                <ul>
                    <li><Link to="/hostels" onClick={handleLinkClick}>{t('hostels')}</Link></li>
                    <li><Link to="/residents" onClick={handleLinkClick}>{t('residents')}</Link></li>
                    <li>
                        {t('chessboard')}
                        <ul>
                            {hostels.map(hostel => (
                                <li key={hostel.id}>
                                    <Link to={`/chessboard/${hostel.id}`} onClick={handleLinkClick}>{hostel.name}</Link>
                                </li>
                            ))}
                        </ul>
                    </li>
                    <li><Link to="/users/manage" onClick={handleLinkClick}>{t('user_management')}</Link></li>
                </ul>
                <LanguageSwitcher />
            </div>
            {isSidebarOpen && window.innerWidth <= 768 && <div className="overlay" onClick={toggleSidebar}></div>}
        </>
    );
};

export default Sidebar;
