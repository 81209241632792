// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/Dashboard.css */
.dashboard-block ul {
    list-style: none;
    padding: 0;
}

.dashboard-block ul li {
    margin: 5px 0;
    background-color: #f9f9f9;
    padding: 10px;
    border-radius: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Dashboard.css"],"names":[],"mappings":"AAAA,iCAAiC;AACjC;IACI,gBAAgB;IAChB,UAAU;AACd;;AAEA;IACI,aAAa;IACb,yBAAyB;IACzB,aAAa;IACb,kBAAkB;AACtB","sourcesContent":["/* src/components/Dashboard.css */\n.dashboard-block ul {\n    list-style: none;\n    padding: 0;\n}\n\n.dashboard-block ul li {\n    margin: 5px 0;\n    background-color: #f9f9f9;\n    padding: 10px;\n    border-radius: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
