// src/components/Resident/ResidentList.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './ResidentList.css';

const ResidentList = () => {
    const { t } = useTranslation();
    const [residents, setResidents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [filter, setFilter] = useState('occupied');
    const token = localStorage.getItem('token');

    useEffect(() => {
        const fetchResidents = async () => {
            setLoading(true);
            try {
                let queryParams = new URLSearchParams();
                if (filter === 'occupied') {
                    queryParams.append('exists[bed]', 'true');
                } else if (filter === 'unoccupied') {
                    queryParams.append('exists[bed]', 'false');
                }
                queryParams.append('order[bed.room.hostel.id]', 'asc');
                queryParams.append('order[bed.room.id]', 'asc');
                queryParams.append('order[bed.id]', 'asc');

                const response = await axios.get(`/api/residents?${queryParams.toString()}`, {
                    headers: {
                        'Accept': 'application/ld+json',
                        'Authorization': `Bearer ${token}`
                    }
                });
                setResidents(response.data['hydra:member']);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchResidents();
    }, [filter, token]);

    const handleDelete = async (id) => {
        try {
            await axios.delete(`/api/residents/${id}`, {
                headers: {
                    'Accept': 'application/ld+json',
                    'Authorization': `Bearer ${token}`
                }
            });
            setResidents(residents.filter(resident => resident.id !== id));
        } catch (err) {
            setError(err.message);
        }
    };

    if (loading) return <p>{t('loading')}</p>;
    if (error) return <p>{t('error_loading_residents', { error })}</p>;

    // Group residents by hostel
    const residentsByHostel = residents.reduce((acc, resident) => {
        const hostelName = resident.bed ? resident.bed.room.hostel.name : 'No Hostel';
        if (!acc[hostelName]) {
            acc[hostelName] = [];
        }
        acc[hostelName].push(resident);
        return acc;
    }, {});

    return (
        <div>
            <h3>{t('residents')}</h3>
            <div className="filter-container">
                <label htmlFor="filter">{t('filter')}:</label>
                <select
                    id="filter"
                    value={filter}
                    onChange={(e) => setFilter(e.target.value)}
                >
                    <option value="occupied">{t('show_occupied')}</option>
                    <option value="all">{t('show_all')}</option>
                    <option value="unoccupied">{t('show_unoccupied')}</option>
                </select>
            </div>
            <Link to="/residents/create">
                <button className="add-resident-button">{t('add_resident')}</button>
            </Link>
            {Object.keys(residentsByHostel).map(hostelName => (
                <div key={hostelName}>
                    <h4>{hostelName}</h4>
                    <ul className="resident-list">
                        {residentsByHostel[hostelName].map(resident => (
                            <li key={resident.id} className="resident-list-item">
                                <div className="resident-info">
                                    {resident.bed ? (
                                        <div className="room-bed-info">
                                            <Link to={`/hostels/${resident.bed.room.hostel.id}`} className="hostel-link">
                                                <button className="hostel-button">{resident.bed.room.hostel.name}</button>
                                            </Link>
                                            <Link to={`/rooms/${resident.bed.room.id}`} className="room-link">
                                                <button className="room-button">{t('room')} {resident.bed.room.roomNumber}</button>
                                            </Link>
                                            <Link to={`/beds/${resident.bed.id}`} className="bed-link">
                                                <button className="bed-button">{t('bed')} {resident.bed.name}</button>
                                            </Link>
                                        </div>
                                    ) : (
                                        <div className="not-assigned">{t('not_assigned')}</div>
                                    )}
                                </div>
                                <div className="resident-data">
                                    <Link to={`/residents/${resident.id}`} className="resident-name-link">
                                        {resident.name}
                                    </Link>
                                    <a href={`tel:${resident.phoneNumber}`} className="phone-link">{resident.phoneNumber}</a>
                                </div>
                                <div className="resident-buttons">
                                    <Link to={`/residents/${resident.id}`}>
                                        <button className="view-button">{t('view')}</button>
                                    </Link>
                                    <button className="delete-button" onClick={() => handleDelete(resident.id)}>{t('delete')}</button>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            ))}
        </div>
    );
};

export default ResidentList;
