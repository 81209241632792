import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './Login.css';

const Login = () => {
    const { t } = useTranslation();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('/api/login', { email, password }, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            localStorage.setItem('token', response.data.token);
            navigate('/');
        } catch (err) {
            console.error('Login failed', err);
        }
    };

    return (
        <div className="login-page">
            <div className="login-container">
                <div className="login-box">
                    <h2>{t('login_title')}</h2>
                    <form onSubmit={handleLogin} className="login-form">
                        <div className="input-group">
                            <label>{t('email_label')}</label>
                            <input
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </div>
                        <div className="input-group">
                            <label>{t('password_label')}</label>
                            <input
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                        </div>
                        <button type="submit" className="login-button">{t('login_button')}</button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Login;
