// src/components/Bed/BedView.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './BedView.css';

const BedView = () => {
    const { id } = useParams();
    const { t } = useTranslation();
    const [bed, setBed] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const token = localStorage.getItem('token');

    useEffect(() => {
        const fetchBed = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`/api/beds/${id}`, {
                    headers: {
                        'Accept': 'application/ld+json',
                        'Authorization': `Bearer ${token}`
                    }
                });
                setBed(response.data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchBed();
    }, [id]);

    if (loading) return <p>{t('loading')}</p>;
    if (error) return <p>{t('error_loading_bed', { error })}</p>;
    if (!bed) return <p>{t('no_bed_found')}</p>;

    return (
        <div>
            <h3>
                <Link to={`/hostels/${bed.room.hostel.id}`}>
                    {bed.room.hostel.name}
                </Link> /
                <Link to={`/rooms/${bed.room.id}`}>
                    {t('room')} {bed.room.roomNumber}
                </Link> /
                {t('bed')} {bed.name}
            </h3>
            {bed.resident ? (
                <div className="resident-info">
                    <p>{t('resident')}:</p>
                    <p>{t('name')}: <Link to={`/residents/${bed.resident.id}`}>{bed.resident.name}</Link></p>
                    <p>{t('telephone')}: <a href={`tel:${bed.resident.phoneNumber}`}>{bed.resident.phoneNumber}</a></p>
                </div>
            ) : (
                <p>{t('no_resident_assigned')}</p>
            )}
            <div>
                <Link to="/rooms">
                    <button className="back-button">{t('back_to_rooms')}</button>
                </Link>
            </div>
        </div>
    );
};

export default BedView;
