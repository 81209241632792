import React, { useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './RateCreate.css';

const RateCreate = () => {
    const { t } = useTranslation();
    const { id } = useParams(); // ID комнаты
    const navigate = useNavigate();

    const [dailyRate, setDailyRate] = useState('');
    const [weeklyRate, setWeeklyRate] = useState('');
    const [monthlyRate, setMonthlyRate] = useState('');
    const [error, setError] = useState(null);
    const token = localStorage.getItem('token');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.post('/api/rates', {
                room: `/api/rooms/${id}`,
                dailyRate: parseFloat(dailyRate),
                weeklyRate: parseFloat(weeklyRate),
                monthlyRate: parseFloat(monthlyRate)
            }, {
                headers: {
                    'Content-Type': 'application/ld+json',
                    'Authorization': `Bearer ${token}`
                }
            });
            navigate(`/rooms/${id}`, {
                headers: {
                    'Accept': 'application/ld+json',
                    'Authorization': `Bearer ${token}`
                },
            });
        } catch (err) {
            setError(err.message);
        }
    };

    return (
        <div>
            <h3>{t('create_rate')}</h3>
            <form onSubmit={handleSubmit} className="rate-form">
                <label>
                    {t('daily_rate')}
                    <input
                        type="number"
                        value={dailyRate}
                        onChange={(e) => setDailyRate(e.target.value)}
                        required
                    />
                </label>
                <label>
                    {t('weekly_rate')}
                    <input
                        type="number"
                        value={weeklyRate}
                        onChange={(e) => setWeeklyRate(e.target.value)}
                        required
                    />
                </label>
                <label>
                    {t('monthly_rate')}
                    <input
                        type="number"
                        value={monthlyRate}
                        onChange={(e) => setMonthlyRate(e.target.value)}
                        required
                    />
                </label>
                {error && <p className="error">{t('error', { error })}</p>}
                <button type="submit" className="submit-button">{t('submit')}</button>
            </form>
        </div>
    );
};

export default RateCreate;
