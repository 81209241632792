// src/App.js
import React, { Suspense } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import AppContent from './AppContent';
import './App.css';
import './i18n';

function App() {
    return (
        <Suspense fallback={<div>Loading translations...</div>}>
            <Router>
                <AppContent />
            </Router>
        </Suspense>
    );
}

export default App;
